<!--
 * @Author: lbh
 * @Date: 2023-08-09 18:25:06
 * @LastEditors: lbh
 * @LastEditTime: 2023-11-04 11:22:31
 * @Description: file content
-->
<template>
  <div class="edit-cellular-module-box">
    <div
      class="cell-b"
      v-if="type == 'bigTitle' || type == 'bigDesc'"
    >
      <selfCell
        title="標題"
        :column="true"
      >
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}title`]"
          :placeholder="`請輸入-${lItem.name}`"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}title`)"
        />
      </selfCell>
      <selfCell
        title="標題"
        :column="true"
      >
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}desc`]"
          :placeholder="`請輸入-${lItem.name}`"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}desc`)"
          type="textarea"
          rows="5"
        />
      </selfCell>

      <selfCell title="背景圖">
        <selfUpload
          v-model="configs.bg"
          type="default"
          @change="setValue('bg')"
        />
      </selfCell>

      <selfCell title="背景顏色">
        <el-color-picker
          v-model="configs.bgColor"
          @change="setValue('bgColor')"
        ></el-color-picker>
      </selfCell>

    </div>
    <!--  -->

    <div class="cell-b">
      <selfCell title="顯示總行">
        <el-input-number
          v-model="configs.gridRows"
          @change="setValue('gridRows')"
          :min="1"
          :max="14"
        ></el-input-number>
      </selfCell>
      <selfCell title="顯示總列">
        <el-input-number
          v-model="configs.gridColumns"
          @change="setValue('gridColumns')"
          :min="1"
          :max="14"
        ></el-input-number>
      </selfCell>
    </div>

    <div
      v-if="type == 'container'"
      class="cell-b"
    >
      <el-button
        type="danger"
        icon="el-icon-delete"
        circle
        @click="clearImg"
      ></el-button>
      <selfCell title="位置下標">
        <el-input
          v-model="itemData_.index"
          placeholder="請輸入位置"
          @blur="setValue_check('items')"
        />
      </selfCell>
      <selfCell title="圖片">
        <selfUpload
          v-model="itemData_.img"
          type="default"
          @change="setValue_('items')"
          style="width:200px"
        />

      </selfCell>
    </div>
  </div>
</template>

<script>
import langEditMixin from '../../mixins/langEdit'
import editModuleMixin from "../../mixins/editModuleMixin"
export default {
  name: "edit-cellular-module",
  mixins: [langEditMixin, editModuleMixin],
  data () {
    return {
      itemData_: {},
    }
  },
  mounted () {
    let items = this.$util.object.clone(this.configs.items);
    let itemData_ = {
      index: this.index,
      img: ""
    }
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.index == this.index) {
        itemData_ = item;
        break;
      }
    }
    this.itemData_ = itemData_;
  },
  methods: {
    setValue_check (key) {
      let itemData_ = this.itemData_;
      // 更改後的 下標
      let i_index = itemData_.index;
      // 原下標
      let index = this.index;
      // 數據組
      let items = this.configs.items;
      // 新數據組
      let newItems = this.$util.object.clone(items);
      // 替換原數據
      let f = false;
      for (let i = 0; i < newItems.length; i += 1) {
        let ele = newItems[i];
        if (ele.index == i_index) {
          ele.img = itemData_.img;
          f = true;
        } else if (ele.index == index) {
          for (let j = 0; j < items.length; j += 1)
            if (items[j].index == i_index) {
              ele.img = items[j].img;
              break;
            }

        }
      }
      if (!f) {
        for (let i = 0; i < newItems.length; i += 1) {
          if (items[i].index == index) {
            newItems[i].index = itemData_.index;
            break;
          }
        }
      }
      this.$emit('setValue', { key: key, value: newItems });
    },
    setValue_ (key) {
      let itemData_ = this.itemData_;
      let items = this.configs.items;
      let newItems = [];
      let f = false;
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.index == itemData_.index) {
          newItems.push(itemData_)
          f = true;
        }
        else
          newItems.push(item);
      }
      if (!f)
        newItems.push(itemData_)

      this.$emit('setValue', { key: key, value: newItems });
    },
    clearImg () {
      this.itemData_.img = '';
      this.setValue_('items')
    },
  }
}
</script>

<style lang="less" scoped>
</style>