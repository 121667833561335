/*
 * @Author: lbh
 * @Date: 2023-08-08 17:05:10
 * @LastEditors: lbh
 * @LastEditTime: 2023-08-08 17:06:02
 * @Description: file content
 */
let mixin = {
  props: {
    type: {
      default() {
        return 'title';
      },
    },
    configs: {
      default() {
        return {};
      },
    },
    index: {
      default() {
        return 0;
      },
    },
  },
  methods: {
    setValue(key) {
      this.$emit('setValue', { key: key, value: this.configs[key] });
    },
  },
};

export default mixin;
